import React from 'react';
import LayoutWrapper from '../components/LayoutWrapper/LayoutWrapper';
import design from '../config/design';
import { useStaticQuery, graphql } from 'gatsby';
import Title from '../components/Title/Title';
import Button from '../components/Button/Button';
import { View } from '../components/View/View';
import Text from '../components/Text/Text';
import ClientCardList from '../components/ClientCard/ClientCardList';
import { GridContainer } from '../components/Grid/Grid';
import { BigView } from '../components/BigView/BigView';

function Clients() {
  const { clients } = useStaticQuery(
    graphql`
      query {
        clients: allDatoCmsClientpage {
          edges {
            node {
              buttonUrl
              subtitle
              textButton
              title
              clientsTitle
              clientLogo {
                url
              }
            }
          }
        }
      }
    `
  );

  const clientContent = clients.edges[0].node;

  return (
    <>
      <LayoutWrapper>
        <BigView>
          <View
            top={3.5}
            desktopLeft={6}
            desktopRight={6}
            mobileLeft={1}
            mobileRight={1}
            tabletLeft={3}
            tabletRight={3}
            bottom={2}
          >
            <Title
              color={design.colors.blue150}
              content={clientContent.title}
            />
            <Text color={design.colors.dark} content={clientContent.subtitle} />
            <View top={3} left>
              <Button
                href={clientContent.buttonUrl}
                content={clientContent.textButton}
                bgColor="primaryOrange"
                type="rounded"
                width="block"
              />
            </View>
            <View top={5} />

            <Title
              color={design.colors.blue150}
              content={clientContent.clientsTitle}
            />

            <View top={1.5} />
            <GridContainer size={200} auto={'auto auto auto'} gridGap={35}>
              {clientContent.clientLogo.map(item => (
                <img
                  src={item.url}
                  alt={'logo do cliente'}
                  width="120"
                  height="auto"
                />
              ))}
            </GridContainer>
            {/* <ClientCardList items={clientContent.clientsList} /> */}

            <View top={5} />
          </View>
        </BigView>
      </LayoutWrapper>
    </>
  );
}

export default Clients;
